import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import createStore from './store/createStore'

const { store, persistor } = createStore()
  const routes = require('./utils/routes').default(store)

ReactDOM.render(
  <App store={store} routes={routes} persistor={persistor} />,
  document.getElementById('root')
);
