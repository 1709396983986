import React from 'react'
import { Container } from '@material-ui/core'

const CoreLayout = ({ children }) => {

  return (
		<Container component="main" maxWidth="md">
			{children}
		</Container>
  )
}

export default CoreLayout