import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@material-ui/core/styles'
import { PersistGate } from 'redux-persist/integration/react'
import { SnackbarProvider } from 'notistack';
import Collapse from '@material-ui/core/Collapse';
import Theme from '../theme'
import { CssBaseline } from '@material-ui/core';

function App({ routes, store, persistor }) {
  return (
    <div>
      <ThemeProvider theme={Theme}>
        <Provider store={store}>
          <SnackbarProvider 
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            TransitionComponent={Collapse}
          >
            <PersistGate loading={null} persistor={persistor}>
              <CssBaseline />
              <Router>{routes}</Router>
            </PersistGate>
          </SnackbarProvider>
        </Provider>
      </ThemeProvider>
    </div>
  );
}

export default App;
