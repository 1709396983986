import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import axios from 'axios'

export default function CreateUser({token, emitChange}) {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState({
    email: '',
    password: '',
    admin: false
  })

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  }

  const handleCreate = () => {
    axios.post('https://bakken-admin.herokuapp.com/users', { state }, { 
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(() => {
        emitChange()
        handleClose()
      })
      .catch(err => console.warn(err))
  }

  return (
    <div>
      <Button color="inherit" onClick={handleClickOpen}>
        Opret ny bruger
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Opret ny bruger</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Addresse"
            type="email"
            name='email'
            value={state.email}
            onChange={handleChange}
            fullWidth
          />
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Kodeord"
            name='password'
            type="text"
            value={state.password}
            onChange={handleChange}
            fullWidth
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={state.admin}
                name='admin'
                onChange={handleChange}
                color="primary"
              />
            }
            label="Administrator"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Luk
          </Button>
          <Button onClick={handleCreate} color="primary">
            Opret
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
