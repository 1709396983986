// ------------------------------------
// Constants
// ------------------------------------
export const SET_LOCATION = 'SET_LOCATION'

// ------------------------------------
// Reducer
// ------------------------------------
export default function locationReducer (state = {}, action) {
  switch (action.type) {
    case SET_LOCATION:
      return { ...action.payload }
    default:
      return state
  }
}
