import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { IconButton, AppBar} from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { 
  BrowserQRCodeReader,
  NotFoundException,
  ChecksumException,
  FormatException
} from '@zxing/library'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CameraScanner = ({ scanBarcode }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const [videoInputDevices, setVideoInputDevices] = useState([]);
  const [code, setCode] = useState("");
  const [searching, setSearching] = useState(false);

  const codeReader = new BrowserQRCodeReader();

  useEffect(() => {
    if(open) {
      codeReader
        .getVideoInputDevices()
        .then(videoInputDevices => {
          setupDevices(videoInputDevices);
        })
        .catch(err => {
          console.error(err);
        });
    } else {
      codeReader.stopContinuousDecode()
      codeReader.reset()
    }
  }, [open])

  function setupDevices(videoInputDevices) {
    // selects last device
    setSelectedDeviceId(videoInputDevices[videoInputDevices.length - 1].deviceId);
  }

  function decodeContinuously(selectedDeviceId) {
    codeReader.decodeFromInputVideoDeviceContinuously(
      selectedDeviceId,
      "video",
      (result, err) => {
        if (result) {
          setOpen(false)
          scanBarcode(result.text)
          codeReader.reset()
          return false
        }

        if (err) {
          setCode("");

          // As long as this error belongs into one of the following categories
          // the code reader is going to continue as excepted. Any other error
          // will stop the decoding loop.
          //
          // Excepted Exceptions:
          //
          //  - NotFoundException
          //  - ChecksumException
          //  - FormatException

          if (err instanceof NotFoundException) {
            // console.log("No QR code found.");
          }

          if (err instanceof ChecksumException) {
            // console.log("A code was found, but it's read value was not valid.");
          }

          if (err instanceof FormatException) {
            // console.log("A code was found, but it was in a invalid format.");
          }
        }
      }
    );
  }

  useEffect(
    deviceId => {
      if(open) { 
        decodeContinuously(selectedDeviceId);
      }
    },
    [selectedDeviceId, open]
  );

  return (
  <>
    <IconButton onClick={handleClickOpen} style={{ minWidth: 80 }} color="primary" aria-label="upload picture" component="span">
      <PhotoCamera />
    </IconButton>
      <div>
        <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Scan barcode
              </Typography>
              <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <video 
          style={{
            minWidth: '100%',
            minHeight: '100%',
            width: 'auto',
            height: 'auto',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            position: 'absolute'
          }} 
            id="video" />
        </Dialog>
      </div>
  </>
  );
}
export default CameraScanner