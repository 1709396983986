import React, { useState, useEffect } from 'react'
import { LOCATION_ROUTE } from '../constants/routes'
import { useHistory } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { Box, Button, Container, Paper, TextField, Fab, Typography } from '@material-ui/core'
import Carousel from 'react-material-ui-carousel'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import theme from '../theme'
import { AddAlertRounded, PersonAddDisabledOutlined } from '@material-ui/icons'
import CameraScanner from '../components/CameraScanner'
import ReactSwipeButton from 'react-swipe-button'

const FrontPage = ({ selectedLocation }) => {
	const history = useHistory()
	const dispatch = useDispatch()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const [ticketInput, setTicketInput] = useState('')
	const [ticket, setTicket] = useState('')
  const [ticketData, setTicketData] = useState(null)
  const [confirmed, setConfirmed] = useState(false)
	const [print, setPrint] = useState(null)
	const [loading, setLoading] = useState(false)
	const [data, setData] = useState([])

	useEffect(() => {
		if (Object.keys(selectedLocation).length === 0 || selectedLocation === '') history.push(LOCATION_ROUTE)
		// If location data is stale (over 12 hours old), let's retrieve and update store.
		if (Date.now() - selectedLocation.retrieved_at > 60*60*1000) {	
			axios.get(`${process.env.REACT_APP_KIOSK_API}?location=${selectedLocation.name}`)
				.then(response => {
					dispatch({ type: 'SET_LOCATION', payload: {...response.data, retrieved_at:Date.now()} })
				})
				.catch(error => {
					console.log(error);
				})
		}

	}, [selectedLocation, history])

	useEffect(() => {
		if (print) {
			setData(print.product.data)

			if (print.voucher.seeding_ticket_text.length > 0) {
				setData(exisiting => [...exisiting, ...print.voucher.seeding_ticket_text])
			}
		}
	}, [print])

	const handleChange = (e) => {
    setTicketInput(e.target.value);
  };

  const scanBarcode = code => {
    setTicket(code)
    redeemTicket()
  }

  useEffect(() => {
    if (ticket) {
      setLoading(true)
      // axios.put(`${process.env.REACT_APP_VOUCHER_API}?ticket=${ticket.toLowerCase()}&location=${selectedLocation.name}`)
      axios.get(`https://voucher.app.nsmedia.eu/api/v1/vouchers/public-check/${ticket.toLowerCase()}`)
        .then(response => {
          console.log('public-check')
          console.log(response.data)
          setTicketData(response.data)
          setLoading(false)
          setTicketInput('')
        })
        .catch(error => {
          setTicket('')
          console.log(error)
          console.log(error.response)
          enqueueSnackbar(error?.response?.data?.error || 'Der opstod en fejl', { variant: 'error' })
          setLoading(false)
        })
    }
  }, [ticket])

  useEffect(() => {
      if (confirmed) {
        setLoading(true)
        // axios.put(`${process.env.REACT_APP_VOUCHER_API}?ticket=${ticket.toLowerCase()}&location=${selectedLocation.name}`)
        axios.put(`https://voucher.api.nsmedia.eu/v1/vouchers/${ticket.toLowerCase()}?location=${selectedLocation.name}`)
          .then(response => {
            console.log('voucher-post')
            enqueueSnackbar('Indløsningen blev gennemført', { variant: 'success' })
            setPrint(response.data)
            setLoading(false)
            setTicketInput('')
          })
          .catch(error => {
            setTicket('')
            console.log(error)
            console.log(error.response)
            enqueueSnackbar(error?.response?.data?.error || 'Der opstod en fejl', { variant: 'error' })
            setLoading(false)
          })
      }
    }, [confirmed])

	const redeemTicket = () => {
    console.log(ticket)
	}

	if (print) {

		// Based on old code base, so keys & values are still the same.
		const values = {
			'{{code}}': ticket.toUpperCase(),
			'{{date}}': new Date().toLocaleDateString( 'en-gb' ),
			'{{datetime}}': `${new Date().toLocaleDateString( 'en-gb' )} ${new Date().toLocaleTimeString( 'en-gb' )}`,
			'{{location}}': selectedLocation.name,
			'{{location-display-name}}': selectedLocation.display_name,
			'{{id}}': print.voucher._id,
			//'{{test}}': print.voucher.test
		};

		return (
			<Container maxWidth='sm'>
				<Box minHeight='100vh' display='flex' alignItems='center' justifyContent='center' flexDirection='column' paddingBottom='4em'>
					{
						data.map((product, i) => {
							const mutatedProduct = Object.keys(values).reduce((prevProduct, key) => prevProduct.replace(new RegExp(key, "gi"), values[key]), product)

							return (
								<Paper key={i} elevation={2} style={{ maxWidth: 400, margin: theme.spacing(2, 2, 0, 2), padding: theme.spacing(2, 2, 0, 2) }}>
									<div dangerouslySetInnerHTML={{__html: mutatedProduct}}></div>
								</Paper>
							)
						})
					}
					<Paper elevation={2} style={{ maxWidth: 400, margin: theme.spacing(2), width: '100%' }}>
						<Button
							fullWidth
							disableElevation
							variant="contained"
							color="primary"
							startIcon={<ArrowBackIcon />}
							onClick={() => {setTicket(''); setPrint(null); setTicketData(null); setConfirmed(false)}}
						>
							Tilbage
						</Button>
					</Paper>
				</Box>
			</Container>
		)
	}

	if (ticketData) {

    let shownTicketData = ticketData?.seating?.orders.length > 0 ? Array(ticketData.seating.orders.length).fill(ticketData.product?.seeding_ticket_data).flat() : ticketData.product.data

    const noneLeft = (ticketData.redeem_date !== null || ticketData.multivouchers_left === 0 )

		return (
      <>
        <Fab 
          style={{position:'fixed', left: '1em', bottom: '1em'}}
          color='primary'
          variant='extended'
          size='large'
          onClick={() => {setTicket(''); setPrint(null); setTicketData(null); setConfirmed(false)}}
        >
          <ArrowBackIcon />
        </Fab>
        <Container maxWidth='sm'>
          <Box minHeight='100vh' display='flex' alignItems='center' justifyContent='center' flexDirection='column' paddingBottom={noneLeft ? '0' : '4em'}>
          {noneLeft && <Paper elevation={2} style={{ backgroundColor: '#df0c00', maxWidth: 400, margin: theme.spacing(2, 2, 0, 2), padding: theme.spacing(2, 2, 2, 2) }}>
            <Typography variant='h6' align='center' style={{color: '#ffe1e1', fontWeight: 'bold'}}>Denne kode er allerede blevet indløst.</Typography>
          </Paper>}
            {

              shownTicketData.map((product, i) => {

                // Based on old code base, so keys & values are still the same.
                let values = {
                  '{{code}}': ticketData.code.toUpperCase(),
                  '{{date}}': new Date().toLocaleDateString( 'en-gb' ),
                  '{{datetime}}': `${new Date().toLocaleDateString( 'en-gb' )} ${new Date().toLocaleTimeString( 'en-gb' )}`,
                  '{{location}}': selectedLocation.name,
                  '{{location-display-name}}': selectedLocation.display_name,
                  '{{id}}': ticketData._id,
                  '{{multivoucher.left}}': ticketData.multivouchers_left ?? 0,
                  '{{multivoucher.total}}': ticketData.multivouchers_total ?? 0,
                  '{{row}}': ticketData?.seating?.orders?.[i].row ?? 0,
                  '{{seat}}':  ticketData?.seating?.orders?.[i].seat ?? 0
                  //'{{test}}': print.voucher.test
                };
                  
                  const mutatedProduct = Object.keys(values).reduce(
                    (prevProduct, key) => 
                      prevProduct.replace(
                        new RegExp(key, "gi"), values[key]), product)

                return (
                  <Paper key={i} elevation={2} style={{ maxWidth: 400, margin: theme.spacing(2, 2, 0, 2), padding: theme.spacing(2, 2, 0, 2) }}>
                    <div dangerouslySetInnerHTML={{__html: mutatedProduct}}></div>
                  </Paper>
                )
              })
            }
            <Paper elevation={0} style={{ maxWidth: 400, margin: theme.spacing(2), width: '100%', backgroundColor: 'transparent' }}>
              
              {!noneLeft &&
              <ReactSwipeButton 
                disabled
                text='Indløs'
                text_unlocked='Indløser..'
                color='#3f51b5'
                onSuccess={() => setConfirmed(true)}
              />}
            </Paper>
          </Box>
        </Container>
      </>
		)
	}

	return (
		<Box minHeight='100vh' display='flex' alignItems='center' justifyContent='center' flexDirection='column'>
			<Carousel
				navButtonsAlwaysInvisible
				animation='slide'
				indicators={false}
				stopAutoPlayOnHover={false}
			>
				{ 
					(selectedLocation?.pages?.[0]?.banners ?? []).map((banner, i) => <img key={i} src={banner.thumb} style={{ width: '100%', height: 'auto' }}/>)
				}
			</Carousel>
			<Box display='flex' width={1}>
				<TextField
					value={ticketInput}
					onChange={handleChange}
					variant="outlined"
					margin="normal"
					fullWidth
					label="Billet"
					autoFocus
					/>
          <CameraScanner scanBarcode={code => scanBarcode(code)} />
			</Box>
			<Button
				disabled={loading}
				onClick={() => setTicket(ticketInput)}
				type="submit"
				fullWidth
				variant="contained"
				color="primary"
			>
				Indløs billet
			</Button>
		</Box>
	)
}

const mapStateToProps = (state) => {
  return {
    selectedLocation: state.locationReducer
  }
}

export default connect(mapStateToProps)(FrontPage)