import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Chip,
  Avatar,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Container,
  Switch,
  FormGroup,
  IconButton,
  Toolbar,
  AppBar,
  Menu,
  MenuItem,
  Card,
  CardContent,
  CardActions,
  Grid,
  CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LocationIcon from '@material-ui/icons/LocationOn';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import MenuIcon from '@material-ui/icons/Menu';
import Store from '@material-ui/icons/Store';
import Group from '@material-ui/icons/Group';
import { ExitToApp } from '@material-ui/icons';
import { DataGrid } from '@material-ui/data-grid';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import AdminDialog from '../components/AdminDialog'
import PDFGeneration from '../components/PDFGeneration'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));
// Configure Firebase.
const config = {
  apiKey: 'AIzaSyCuMz7U4tvX9yMoIvnLEnv9uEGgEmDW-Ow',
  authDomain: 'nsmedia-60c0e.firebaseapp.com'
};
firebase.initializeApp(config);

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

function SignInScreen() {
  const classes = useStyles();

  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
  const [admin, setAdmin] = useState(false);
  const [adminOpen, setAdminOpen] = useState(false)
  const [pdfOpen, setPdfOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [token, setToken] = useState(null)
  const [locations, setLocations] = useState([])
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [vouchers, setVouchers] = useState([])
  const [filteredVouchers, setFilteredVouchers] = useState([])
  const [filteredProduct, setFilteredProduct] = useState(null)
  const [products, setProducts] = useState([])
  const [chartOptions, setChartOptions] = useState(null);
  const [openDateRange, setOpenDateRange] = useState(false);
  const [dayBefore, setDayBefore] = useState(null);
  const [counts, setCounts] = useState({ before: <CircularProgress />, last: <CircularProgress /> })
  const date = new Date(new Date().setHours(23,59,59,999))
  const [dateRange, setDateRange] = useState({
    startDate: new Date(date.getFullYear(), date.getMonth(), 1),
    endDate: date
  });

  useEffect(() => {
    if (dateRange.endDate) {
      const preDate = new Date(dateRange.endDate)
        preDate.setDate(new Date(dateRange.endDate).getDate() - 1)
        preDate.setHours(23,59,59,999)
      setDayBefore(preDate)
    }
  }, [dateRange])

  useEffect(() => {
    if (vouchers.length > 0) {
      const unique = [...new Set(vouchers.map(item => item.product_id))];
      setProducts(unique)
    }
  }, [vouchers])

  useEffect(() => {
    if(vouchers.length > 0 && dateRange.startDate && dateRange.endDate && dayBefore) {
      const filtered = vouchers.filter(voucher => {
        const check = new Date(voucher.redeem_date)
        if((check.getTime() <= dateRange.endDate.getTime() && check.getTime() >= dateRange.startDate.getTime())) {
          if(filteredProduct) {
            if(filteredProduct == voucher.product_id) return voucher
          } else {
            return voucher
          }
        }
      })

      setFilteredVouchers(filtered)

      const lastDayCount = vouchers.filter(voucher => {
        const check = new Date(voucher.redeem_date)
        if((check.getTime() <= dateRange.endDate.getTime() && check.getTime() >= dateRange.endDate.getTime() - 1000*60*60*24)) {
          if(filteredProduct) {
            if(filteredProduct == voucher.product_id) return voucher
          } else {
            return voucher
          }
        }
      })

      const beforeDayCount = vouchers.filter(voucher => {
        const check = new Date(voucher.redeem_date)
        if((check.getTime() <= dayBefore && check.getTime() >= dayBefore - 1000*60*60*24)) {
          if(filteredProduct) {
            if(filteredProduct == voucher.product_id) return voucher
          } else {
            return voucher
          }
        }
      })
      
      setCounts({before: beforeDayCount.length, last: lastDayCount.length})

    }
  }, [dateRange, vouchers, dayBefore, filteredProduct])

  const open = Boolean(anchorEl);

  const toggleDateRange = () => setOpenDateRange(!openDateRange);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  useEffect(() => {
    if(isSignedIn) {
      const token = firebase.auth().currentUser.getIdToken()
      .then(token => setToken(token))
    }
  }, [firebase, isSignedIn])

  useEffect(() => {
    if (token) {
      axios.get('https://bakken-admin.herokuapp.com/', { 
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(resp => {
          if (resp.data.locations.length > 0) {
            setLocations(resp.data.locations)
            setSelectedLocation(resp.data.locations[0])
          }
          if (resp.data.isAdmin) { 
            setAdmin(true)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [token])

  useEffect(() => {
    if(selectedLocation) {
      axios.get(`https://bakken-admin.herokuapp.com/${selectedLocation}`, { 
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(resp => {
          setVouchers(resp.data)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, selectedLocation)

  const random_rgba = () => {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r()*s) + ',' + o(r()*s) + ',' + o(r()*s) + ',0.5)';
  }

  useEffect(() => {
    if (vouchers.length > 0) {

      const values = Object.entries(_.groupBy(vouchers, 'product_id')).map(val => {
        const [key, value] = val
        return { 
              name: key,
              color: random_rgba(),
              data: value.map(val => [new Date(val.redeem_date).getTime(), 1])
            }
      })

      setChartOptions({ 
        credits: {
          enabled: false
        },
        title: false,
        chart: {
          type: 'column',
          zoomType: 'x'
        },
        xAxis: {
          type: 'datetime',
            title: {
                enabled: true,
                text: 'Tid'
            },
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true,
        },
        yAxis: {
            title: {
                text: 'Indløsninger'
            },          
        },
        plotOptions: {
          series: {
            dataGrouping: {
              forced: true,
              units: [
                ['year', [1]]
              ]
            }
          },
          column: {
            stacking: 'normal'
          }
        },        
        series: values
      })
    }
  }, [vouchers])

  if (!isSignedIn) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh'}}>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      </div>
    );
  }

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'product_id', headerName: 'Produkt', width: 200},
    // {
    //   field: 'redeem_location',
    //   headerName: 'Lokation',
    //   width: 150,
    //   editable: false,
    // },
    {
      field: 'issue_date',
      headerName: 'Udstedt',
      type: 'date',
      width: 200,
      sortable: true,
    },
    {
      field: 'redeem_date',
      headerName: 'Indløsningsdato',
      type: 'date',
      width: 200,
      sortable: true,
    },
    {
      field: 'redeem_time',
      headerName: 'Indløsningstidspunkt',
      type: 'date',
      width: 100,
      sortable: false,
    },
    {
      field: 'code',
      headerName: 'Kode',
      sortable: false,
      width: 160,
    },
  ];
  
  const options = {
    title: {
      text: 'My chart'
    },
    series: [{
      data: [1, 2, 3]
    }]
  }
  
  return (
    <>
      {admin && <AdminDialog opener={adminOpen} closer={() => setAdminOpen(false)} token={token} />}
      {admin && <PDFGeneration opener={pdfOpen} closer={() => setPdfOpen(false)} />}
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {selectedLocation ? <>Se indløsninger: {selectedLocation}</> : <>NSMedia</>}
          </Typography>
          
            <div>
            {admin && <IconButton
                  aria-label=""
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => setPdfOpen(true)}
                  color="inherit"
                >
                <PictureAsPdfIcon />
              </IconButton>}
            {admin && <IconButton
                  aria-label=""
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => setAdminOpen(true)}
                  color="inherit"
                >
                <Group />
              </IconButton>}
              <IconButton
                aria-label="Change selected date range"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDateRange}
                color="inherit"
              >
                <DateRangeIcon />
              </IconButton>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Store />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                {locations && locations.map((location, i) => (
                  <MenuItem key={i} onClick={() => { setSelectedLocation(location); setAnchorEl(null);}}>{location}</MenuItem>
                ))}
              </Menu>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => firebase.auth().signOut()}
                color="inherit"
              >
                <ExitToApp  />
              </IconButton>
            </div>
        </Toolbar>
      </AppBar>

      <DateRangePicker
        initialDateRange={dateRange}
        open={openDateRange}
        toggle={toggleDateRange}
        onChange={(range) => {
          range.endDate.setHours(23,59,59,999)
          setDateRange(range);
          toggleDateRange();
        }}
      />
      {chartOptions &&
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions}
      />}
      {(vouchers && locations.length > 0) ?
        <div style={{ height: 'calc(100vh - 100px)', width: '100%' }}>
          <Grid container spacing={2} style={{padding: '10px 0'}}>
            <Grid item xs={4}>
              <Card variant='outlined'>
                <CardContent style={{textAlign: 'center', padding: 0}}>
                  <h4>Indløsninger: {dateRange.startDate.toLocaleDateString('da-DK')} - {dateRange.endDate.toLocaleDateString('da-DK')}</h4>
                  <h2>{filteredVouchers.length}</h2>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card variant='outlined'>
                <CardContent style={{textAlign: 'center', padding: 0}}>
                  <h4>Indløsninger d. {dayBefore && new Date(dayBefore).toLocaleDateString('da-DK')}</h4>
                  <h2>{counts.before}</h2>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card variant='outlined'>
                <CardContent style={{textAlign: 'center', padding: 0}}>
                  <h4>Indløsninger d. {dateRange.endDate.toLocaleDateString('da-DK')}</h4>
                  <h2>{counts.last}</h2>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{paddingBottom: '10px'}}>
            <Grid item xs={12} style={{paddingTop: 0}}>
              <Card variant='outlined'>
                <CardContent>
                  {products.map((product, i) => 
                    <Chip key={i}
                      style={{margin: '3px'}}
                      color={filteredProduct == product ? 'secondary' : 'primary'}
                      label={product}
                      onClick={() => setFilteredProduct(product)}
                      onDelete={filteredProduct == product ? () => setFilteredProduct(null) : null}
                    /> 
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          <DataGrid
            rows={filteredVouchers.map(v => {
              return { id: v._id, redeem_location: v.redeem_location, issue_date: new Date(v.issue_date), redeem_date: new Date(v.redeem_date), redeem_time: new Date(v.redeem_date).toLocaleTimeString('it-IT'), code: v.code, product_id: v.product_id}
            })}
            sortModel={
              [{
                field: 'redeem_date',
                sort: 'desc'
              }]
            }
            columns={columns}
            pageSize={20}
            disableSelectionOnClick
          />
        </div>
        :
        <Typography variant='h6' style={{marginTop: 50, textAlign: 'center'}}>Hovsa. Det ser ud til at du endnu ikke er tilknyttet en lokation. <br></br> Kontakt venligst <a href="mailto:thomas@nsmedia.dk">thomas@nsmedia.dk</a> for assistance.</Typography>
      }
    </>
  );
}

export default SignInScreen;
