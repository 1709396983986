import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

import axios from 'axios'

export default function EditUser({ user, token, emitChange }) {
  const [open, setOpen] = React.useState(false);
  const [locations, setLocations] = React.useState(user.locations.join(','));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setLocations(e.target.value)
  }

  const saveChanges = () => {
    axios.put('https://bakken-admin.herokuapp.com/users', { user, locations: locations.split(',') }, { 
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(() => {
        emitChange()
        handleClose()
      })
      .catch(err => console.warn(err))
  }

  return (
    <div>
      <IconButton edge="end" aria-label="edit" onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{user.email}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            id="locations"
            label="Lokationer"
            type="text"
            onChange={handleChange}
            value={locations}
            fullWidth
            helperText='Dette felt skal indeholde lokationer som brugeren have adgang til. Disse lokationer skal blot skrives ud og være kommasepareret.'
          />
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Luk
          </Button>
          <Button onClick={saveChanges} color="primary">
            Gem
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
