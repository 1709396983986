import React from 'react'
import { Route, Switch } from 'react-router-dom'
import CoreLayout from '../components/CoreLayout'
import { BACKEND_ROUTE, LOCATION_ROUTE } from '../constants/routes'
import FrontPage from '../pages/FrontPage'
import Backend from '../pages/Backend'
import Location from '../pages/Location'

export default function createRoutes () {
  return (
		<CoreLayout>
			<Switch>
				<Route exact path='/' component={FrontPage} />
				<Route exact path={LOCATION_ROUTE} component={Location} />
				<Route exact path={BACKEND_ROUTE} component={Backend} />
				{/* <Route component={NotFoundPage} /> */}
			</Switch>
		</CoreLayout>
  )
}
