import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Hidden from '@material-ui/core/Hidden'
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Slide from '@material-ui/core/Slide';
import QRCode from 'qrcode.react'
import ReactToPdf from 'react-to-pdf'

import { fontSize } from '@material-ui/system';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ opener, closer}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [code, setCode] = React.useState('kagefy');
  const [PDFDownload, setPDFDownload] = React.useState(false);
  const [state, setState] = React.useState({
    title: 'Produkt voucher',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    codes: ''
  })
  const ref = React.createRef()
  const generate = React.useRef(null)

  useEffect(() => {
    setOpen(opener)
  }, [opener])

  const startDownload = () => {
    const codes = state.codes.split("\n")

    codes.forEach((code,i) => {
      setTimeout(function(){
        setCode(code)
        generate.current.click()
      }, 3000 * (i + 1));
    })
  }

  const handleClose = () => {
    closer(false);
  };

  const handleChange = (evt) => {
    const value =
      evt.target.type === "checkbox" ? evt.target.checked : evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  }

  return (
    <div>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              PDF generator
            </Typography>
          </Toolbar>
        </AppBar>
        <Container>
          <Grid container>
            <Grid item xs={12} md={4} style={{minHeight: '100vh'}}>
              <h2>Opsæt indhold</h2>

              <TextField 
                fullWidth
                label="Titel"
                name='title'
                value={state.title}
                onChange={handleChange}
              />
              <TextField
                fullWidth
                label="Beskrivelse"
                name='description'
                value={state.description}
                onChange={handleChange}
                multiline
                rows={10}
              />

              <h2>Indsæt koder til generering</h2>

              <TextField
                fullWidth
                label="koder"
                multiline
                name='codes'
                helperText='Hver kode skal stå på en ny linje'
                value={state.codes}
                onChange={handleChange}
                rows={20}
              />

              <ReactToPdf targetRef={ref} filename={`${code}.pdf`}>
                  {({toPdf}) => 
                    (
                      <>
                        <Button color='primary' variant='contained' onClick={startDownload}>Generer PDF'er</Button>

                        <div style={{visibility: 'hidden', marginTop: '80px'}}>
                          <Button ref={generate} onClick={toPdf}>Download sidste</Button>
                        </div>
                      </>
                  )}
              </ReactToPdf>
            </Grid>
            <Grid item xs={12} md={8} style={{minHeight: '100vh'}}>
              <Card>
                <CardContent>
                <div style={{width: 794, height: 1123, padding: '60px'}} ref={ref}>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100%', flexDirection: 'column'}}>
                  <h1 style={{fontSize: '60px'}}>{state.title}</h1>
                  <p style={{fontSize: '24px', whiteSpace: 'pre-line'}}>{state.description}</p>
                  <QRCode size={256} style={{marginTop: '40px'}} value={code} />
                  <span>Kode: {code}</span>
                  </div>
                </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </div>
  );
}
