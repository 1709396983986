import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import {
  Avatar,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Container 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LocationIcon from '@material-ui/icons/LocationOn';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: theme.breakpoints.values.sm,
    margin: '0 auto',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Location = ({ selectedLocation }) => {
  const dispatch = useDispatch()
	const history = useHistory()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [location, setLocation] = useState(selectedLocation?.name || '')

  const handleChange = (e) => {
    setLocation(e.target.value);
  };

  const changeLocation = (e) => {
    axios.get(`${process.env.REACT_APP_KIOSK_API}?location=${location}`)
    .then(response => {
      dispatch({ type: 'SET_LOCATION', payload: {...response.data, retrieved_at:Date.now()} })
      enqueueSnackbar('Lokation opdateret', { variant: 'success' })
      history.push('/')
    })
    .catch(error => {
      if (error.response.status === 404) {
        enqueueSnackbar('Den indtastede lokation kunne ikke findes', { variant: 'error' })
      }
      console.log(error);
    })
  };

  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LocationIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Indtast lokation
      </Typography>
      <div className={classes.form}>
        <TextField
          value={location}
          onChange={handleChange}
          variant="outlined"
          margin="normal"
          fullWidth
          label="Lokationskode"
          autoFocus
        />
        <Button
          onClick={changeLocation}
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          Gem lokation
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    selectedLocation: state.locationReducer
  }
}

export default connect(mapStateToProps)(Location)
